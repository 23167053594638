import {
  IonAccordion,
  IonAccordionGroup,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToggle,
  IonToolbar,
} from "@ionic/react";
import React, { useState } from "react";

import {
  calculatorOutline,
  homeOutline,
  moon,
  openOutline,
} from "ionicons/icons";
import myUtil, { APP_NAME } from "../helpers/cha-utils";

const Sidebar: React.FC = () => {
  // Add or remove the "dark" class based on if the media query matches

  const [darkMode, setDarkMode] = useState<boolean>(
    localStorage.getItem(myUtil.prefix + "dark-mode-status") === "true"
  );

  // console.log(darkMode);

  const calculatorLinks = [
    { name: "Physics", url: "category/physics/" },
    { name: "Science", url: "category/science/" },
    { name: "Health", url: "category/health/" },
    { name: "Conversion", url: "category/converter/" },
  ];

  const calculatorLinksJSX = calculatorLinks.map((item) => (
    <IonItem
      href={`https://calculatorhub.app/` + item.url}
      target="_blank"
      rel="noreferrer"
      className="ion-padding-start"
      key={item.name}
    >
      <IonIcon slot="start" icon={openOutline} size="small"></IonIcon>
      <IonLabel style={{ fontSize: "16px" }}>{item.name} Calculators</IonLabel>
    </IonItem>
  ));

  return (
    <IonMenu contentId="main-content">
      <IonHeader>
        <IonToolbar color="primary">
          <IonTitle>{APP_NAME}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-no-padding">
        <IonList lines="none">
          <IonItem lines="none">
            <IonIcon slot="start" icon={moon}></IonIcon>
            <IonLabel>Toggle Dark Mode</IonLabel>
            <IonToggle
              id="themeToggle"
              slot="end"
              checked={darkMode}
              onIonChange={(e) => {
                myUtil.toggleDarkTheme(e.detail.checked);
                setDarkMode(e.detail.checked);
              }}
            ></IonToggle>
          </IonItem>

          <IonAccordionGroup>
            <IonAccordion>
              <IonItem slot="header" lines="none">
                <IonIcon slot="start" icon={calculatorOutline}></IonIcon>
                <IonLabel>More Calculators</IonLabel>
              </IonItem>
              <div slot="content">
                <IonList lines="none" className="ion-padding-left">
                  {calculatorLinksJSX}
                </IonList>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
          <IonItem
            href="https://calculatorhub.app/about/"
            target="_blank"
            rel="noreferrer"
          >
            <IonIcon icon={homeOutline} slot="start"></IonIcon>
            <IonLabel>About CalculatorHub</IonLabel>
          </IonItem>
        </IonList>
      </IonContent>

      <IonItem
        href="https://calculatorhub.app"
        target="_blank"
        rel="noreferrer"
        lines="none"
      >
        <IonLabel color="primary">calculatorhub.app</IonLabel>
      </IonItem>
    </IonMenu>
  );
};

export default Sidebar;
