import {
  IonCard,
  IonText,
} from "@ionic/react";
import { ReactNode } from "react";
import clasess from "./ResultSection1.module.css";

type Props = {
  title?: ReactNode;
  subtitleTop?: string;
  subtitleBottom?: ReactNode;
  subtitleBottomColor?: string;
};
const ResultSection1: React.FC<Props> = (props) => {
  return (
    <IonCard className={clasess.card}>
      <div className={clasess.subtitleTop}>{props.subtitleTop}</div>
      <div className={clasess.title}>{props.title}</div>
      <IonText className={clasess.subtitleBottom} color={props.subtitleBottomColor}>{props.subtitleBottom}</IonText>
    </IonCard>
  );
};
export default ResultSection1;